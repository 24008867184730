import request from './request'

//工序工位
export function checkList(data) {
  return request({
    url: '/index.php/bao_quan/check_list',
    method: 'post',
    data
  })
}
export function checkFind(data) {
  return request({
    url: '/index.php/filter/check_find',
    method: 'post',
    data
  })
}
export function submitCheck(data) {
  return request({
    url: '/index.php/bao_quan/submit_check',
    method: 'post',
    data
  })
}
export function checkInformation(data) {
  return request({
    url: '/index.php/filter/check_information_find',
    method: 'post',
    data
  })
}
export function checkInformationSubmit(data) {
  return request({
    url: '/index.php/bao_quan/check_information_submit',
    method: 'post',
    data
  })
}
//车辆离场提交审核
export function leaveData(data) {
  return request({
    url: '/index.php/bao_quan/leave_data',
    method: 'post',
    data
  })
}


