<template>
    <div>
        <Head :title="'提车审核'" :out="'CarOut'" />
        <div class="ppp">
            <p>
                请拍摄下列指定照片：
            </p>
            <div class="imgbox">
                <p>提车人身份证正面：</p>
                <!-- :after-read="uploadershang" :before-delete="deleteimg" -->
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList1" :after-read="uploadershang(1)" :before-delete="deleteimg(1)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!picker_id_positive" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'shenZ')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="picker_id_positive" @click="bigImg(picker_id_positive)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('shenZ')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="imgbox">
                <p>提车人身份证背面：</p>
                

                <div v-if="!isWechat">
                    <van-uploader v-model="fileList2" :after-read="uploadershang(2)" :before-delete="deleteimg(2)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!imageUrlF" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'Fan')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="imageUrlF" @click="bigImg(imageUrlF)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('Fan')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="imgbox">
                <p>提车凭证原件照片：</p>
                

                <div v-if="!isWechat">
                    <van-uploader v-model="fileList3" :after-read="uploadershang(3)" :before-delete="deleteimg(3)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!image_script" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'Pz')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="image_script" @click="bigImg(image_script)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('Pz')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="imgbox">
                <p>提车现场拖车车头斜45度+提车人照片：</p>
                

                <div v-if="!isWechat">
                    <van-uploader v-model="fileList4" :after-read="uploadershang(4)" :before-delete="deleteimg(4)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!positive_car_image" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'tou45')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="positive_car_image" @click="bigImg(positive_car_image)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('tou45')" />
                        </van-image>
                    </div>
                </div>
            </div>
            <div class="imgbox">
                <p>提车现场车尾斜45度照片：</p>
                
                <div v-if="!isWechat">
                    <van-uploader v-model="fileList5" :after-read="uploadershang(5)" :before-delete="deleteimg(5)" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!back_car_image" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'wei45')">
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="back_car_image" @click="bigImg(back_car_image)" >
                            <van-icon class="iconX" name="close" @click.stop="delImgHan('wei45')" />
                        </van-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-tac">
            <van-button type="info" @click="Submit">提交</van-button>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import {Toast} from 'vant'
import {leaveData} from '@/api/audit'
    export default {
        components: {
            Head,
        },
        data() {
            return {
                fileList1: [],
                fileList2: [],
                fileList3: [],
                fileList4: [],
                fileList5: [],
                picker_id_positive :"", //提车人身份证正面',
                imageUrlF :"", //提车人身份证反面',
                image_script :"", //提车凭证原件',
                positive_car_image :"", //提车现场拖车车头斜45度+提车人照片',
                back_car_image :"", //提车现场车尾斜45度照片',
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        mounted () {
            if(this.isWechat){
                this.wcConfig();
            }
            if(this.SFZZ){
                this.picker_id_positive = this.SFZZ 
                this.fileList1 = [{url:this.SFZZ}]
            }
            if(this.SFZF){
                this.imageUrlF = this.SFZF 
                this.fileList2 = [{url:this.SFZF}]
            }
            if(this.PZ){
                this.image_script = this.PZ 
                this.fileList3 = [{url:this.PZ}]
            }
            if(this.CT45){
                this.positive_car_image = this.CT45 
                this.fileList4 = [{url:this.CT45}]
            }
            if(this.CW45){
                this.back_car_image = this.CW45 
                this.fileList5 = [{url:this.CW45}]
            }
        },
        computed: {
            
            QueryId() {
                return this.$route.query.id || '' 
            },
            SFZZ(){
                return window.localStorage.getItem(this.QueryId + 'SFZZ') || ''
            },
            SFZF(){
                return window.localStorage.getItem(this.QueryId + 'SFZF') || ''
            },
            PZ(){
                return window.localStorage.getItem(this.QueryId + 'PZ') || ''
            },
            CT45(){
                return window.localStorage.getItem(this.QueryId + 'CT45') || ''
            },
            CW45(){
                return window.localStorage.getItem(this.QueryId + 'CW45') || ''
            },
        },
        methods: {
            async Submit(){
                try{
                    const toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "加载中..."
                    });
                    let obj = {
                        id:this.QueryId,
                        picker_id_positive:this.picker_id_positive, //提车人身份证正面',
                        picker_id_back:this.imageUrlF, //提车人身份证反面',
                        image_script:this.image_script, //提车凭证原件',
                        positive_car_image:this.positive_car_image, //提车现场拖车车头斜45度+提车人照片',
                        back_car_image:this.back_car_image, //提车现场车尾斜45度照片',
                    }
                    const {data} = await leaveData(obj).then(res=>res)
                    this.$toast.success(data.msg)
                    this.$router.push('/CarOut')
                    toast.clear();
                }catch{}
               
            },
            uploadershang(type) {
                
                return ((file)=>{
                    file.status = "uploading";
                    file.message = "上传中...";
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                   this.$http.post("/index.php/index/upload/index", params)
                   .then((res) => {
                    file.status = "done";
                        if (res.data.code == 0) {
                            switch (type) {
                                case 1:
                                    this.picker_id_positive = res.data.data
                                    window.localStorage.setItem(this.QueryId + 'SFZZ',res.data.data)
                                    break;
                                case 2:
                                    this.imageUrlF = res.data.data
                                    window.localStorage.setItem(this.QueryId + 'SFZF',res.data.data)
                                    break;
                                case 3:
                                    this.image_script = res.data.data
                                    window.localStorage.setItem(this.QueryId + 'PZ',res.data.data)
                                    break;
                                case 4:
                                    this.positive_car_image = res.data.data
                                    window.localStorage.setItem(this.QueryId + 'CT45',res.data.data)
                                    break;
                                case 5:
                                    this.back_car_image = res.data.data
                                    window.localStorage.setItem(this.QueryId + 'CW45',res.data.data)
                                    break;
                            
                                default:
                                    break;
                            }
                        }else if(res.data.code == -1){
                            this.islogout()
                        }else{
                            file.status = "failed";
                            file.message = "上传失败";
                            if(type == 1) {
                                this.fileList1 = []
                                this.picker_id_positive = ''
                                window.localStorage.removeItem(this.QueryId +'SFZZ')
                            }else if(type == 2){
                                this.fileList2 = []
                                this.imageUrlF = ''
                                window.localStorage.removeItem(this.QueryId +'SFZF')
                            }else if(type == 3){
                                this.fileList3 = []
                                this.image_script = ''
                                window.localStorage.removeItem(this.QueryId +'PZ')
                            }else if(type == 4){
                                this.fileList4 = []
                                this.positive_car_image = ''
                                window.localStorage.removeItem(this.QueryId +'CT45')
                            }else if(type == 5){
                                this.fileList5 = []
                                this.back_car_image = ''
                                window.localStorage.removeItem(this.QueryId +'CW45')
                            }
                           
                        }
                    })
                    .catch((res)=>{
                        if(type == 1) {
                                this.fileList1 = []
                                this.picker_id_positive = ''
                                window.localStorage.removeItem(this.QueryId +'SFZZ')
                            }else if(type == 2){
                                this.fileList2 = []
                                this.imageUrlF = ''
                                window.localStorage.removeItem(this.QueryId +'SFZF')
                            }else if(type == 3){
                                this.fileList3 = []
                                this.image_script = ''
                                window.localStorage.removeItem(this.QueryId +'PZ')
                            }else if(type == 4){
                                this.fileList4 = []
                                this.positive_car_image = ''
                                window.localStorage.removeItem(this.QueryId +'CT45')
                            }else if(type == 5){
                                this.fileList5 = []
                                this.back_car_image = ''
                                window.localStorage.removeItem(this.QueryId +'CW45')
                            }
                    })
                })
            },
            deleteimg(type){
                return ((file)=>{
                    if(type == 1) {
                        this.fileList1 = []
                        this.picker_id_positive = ''
                        window.localStorage.removeItem(this.QueryId +'SFZZ')
                    }else if(type == 2){
                        this.fileList2 = []
                        this.imageUrlF = ''
                        window.localStorage.removeItem(this.QueryId +'SFZF')
                    }else if(type == 3){
                        this.fileList3 = []
                        this.image_script = ''
                        window.localStorage.removeItem(this.QueryId +'PZ')
                    }else if(type == 4){
                        this.fileList4 = []
                        this.positive_car_image = ''
                        window.localStorage.removeItem(this.QueryId +'CT45')
                    }else if(type == 5){
                        this.fileList5 = []
                        this.back_car_image = ''
                        window.localStorage.removeItem(this.QueryId +'CW45')
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.ppp{
    margin-top: 52px;
    padding: 10px;
}
.imgbox{
    border-bottom: 1px solid #eee;
}
.btn-tac{
    text-align: center;
    margin-top: 10px;
    .van-button{
        width: 150px;
    }
}
</style>